import React from "react";
import { graphql } from "gatsby";

import Layout from "../../../shared/src/components/layout";
import Head from "../../../shared/src/components/head";
import SmartLink from "../../../shared/src/components/smart-link";

import * as styles from "./index.module.css";

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  const { menu } = data.site.siteMetadata;

  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout location={location} menu={menu} title={siteTitle}>
      <Head title={siteTitle} />
      <h1>
        <span>Blog</span>
      </h1>
      {posts.map((post) => {
        const { node } = post;
        const { date, title } = node.frontmatter;
        return (
          <div key={node.fields.slug}>
            <div className={styles.date}>{date}</div>
            <div className={styles.description}>
              <h3 className={styles.title}>
                <span>
                  <SmartLink to={node.fields.slug}>{title}</SmartLink>
                </span>
              </h3>
              <p
                className={styles.excerpt}
                dangerouslySetInnerHTML={{ __html: node.excerpt }}
              />
            </div>
          </div>
        );
      })}
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        menu
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          html
          excerpt(pruneLength: 300)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`;
